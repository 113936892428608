import React, { useState, useEffect, createContext } from 'react';
// We use Route in order to define the different routes of our application
import { Route, Routes, Link } from "react-router-dom";
// We import all the components we need in our app
import Navbar from "./components/navbar";
import RecordList from "./components/recordList";
// import Edit from "./components/edit";
import Edit from "./components/edit2";
import Create from "./components/create";
import Map from './components/map' // import the map here
import RecordTable from "./components/table";
import Test from "./components/test";
// import Login from "./components/login";
import Navbar2 from "./components/navbar2";
// import Table2 from "./components/table2";
import Login from "./components/login2";
import useToken from './components/useToken';
import Register from "./components/register2";
import jwt_decode from "jwt-decode";
import Test2 from "./components/test2";
import MyGames from "./components/my_games";
import { joinGame, leaveGame } from './components/joinGame';
// import MyGames from "./components/myGames";
// import CreateCourt from "./components/createCourt";
// import MapWithASearchBox from "./components/map2";
import NewTable from "./components/new_table";
import HomepageHK from "./components/homepage_hk";
import RegisterGoogle from "./components/register_google";
import ContactUs from "./components/contactUs";
import FetchData from "./components/fetchData";
import PasswordPage from "./components/passwordPage";
import Profile from "./components/profile";
import MessageBoard from "./components/messageBoard";
import BoardGames from "./components/boardgames";
import Stats from "./components/stats";
import Landing from "./components/landing";
import MapCheckin from "./components/mapCheckin";
import MapSearch from "./components/mapSearch";
import ProductFilterDropdowns from './components/ui-experiment';
import NewTableMobile from './components/new_table_mobile';
import NewTableMobile2 from './components/new_table_mobile2';
import PrivacyPolicy from './components/privacyPolicy';
import { LoadScript, useLoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';


export const AdminContext = createContext();

const SurveyBanner = () => {
    return (
        <div className="survey-banner" style={{ backgroundColor: '#bfefff', textAlign: 'center', fontFamily: 'Comic Sans MS' }}>
            <p style={{ fontSize: '12px' }}>Got ideas on how to improve your experience when organizing sports events? Tell us <b><a href="https://qfreeaccountssjc1.az1.qualtrics.com/jfe/form/SV_6fkHfK7JiS3ZhHw" target="_blank" rel="noopener noreferrer">here</a></b>.
                Share other feedback <Link to="/contactus"><b>here</b></Link>.</p>
            {/* 💪 */}
        </div >
    );
};

const libraries = ['places'];


const App = () => {
    // const [token, setToken] = useState();
    // const token = getToken();
    const { token, setToken } = useToken();
    const [username, setUsername] = useState('');
    const [records, setRecords] = useState([]);
    // const [usernameUpdated, setUsernameUpdated] = useState(false);
    const version = "v3.0";
    const [region, setRegion] = useState(["HK, Victoria Park"]);
    const [courts, setCourts] = useState([]);
    // const [admin, setAdmin] = useState('false')
    const [tennisCourts, setTennisCourts] = useState([]);
    const [bballCourts, setBballCourts] = useState([]);
    const [footballCourts, setFootballCourts] = useState([]);
    const [basketball_google, setBasketball_google] = useState([]);
    const [combinedCourts, setCombinedCourts] = useState([]);
    const [debugMode, setDebugMode] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(true); // set to false to enable Beta-Authentication; true to disable ('nadal')
    const [isAdmin, setIsAdmin] = useState(false);
    const [env, setEnv] = useState("prod"); //set this back to 'prod' before deploying. 'dev' for development

    // Function to handle authentication
    const authenticate = (password) => {
        // Perform your authentication logic here
        // SET BETA AUTH PASSWORD HERE:
        const expectedPassword = 'nadal'; //Beta-auth page password is 'nadal'
        if (password === expectedPassword) {
            setIsAuthenticated(true);
            console.log("Authenticated")
        } else {
            // Handle authentication failure, show error message, etc.
            window.alert("Wrong password.");
        }
    };

    // if (!isAuthenticated) {
    //     // Render the password page if not authenticated
    //     return <PasswordPage authenticate={authenticate} />;
    // }

    useEffect(() => {
        if (token) {
            const decodedToken = jwt_decode(token);
            setUsername(decodedToken.username);
            // console.log("ADMIN?:", decodedToken.admin);
            // console.log("decodedToken:", decodedToken);
            // const admin = decodedToken.admin;
            // setAdmin(admin);
        }
        else {
            setUsername(null)
        }
    }, [token]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM",
        libraries,
    });

    // const decoded = jwt_decode(token);
    // const username = decoded.username;
    // console.log(username); // prints the username of the current user

    // useEffect(() => {
    //     async function getRecords() {
    //         //Fetch game records
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}/record/`);
    //         if (!response.ok) {
    //             const message = `An error occurred while fetching game records: ${response.statusText}`;
    //             window.alert(message);
    //             return;
    //         }
    //         const records = await response.json();
    //         setRecords(records);
    //         console.log("recordsss", records);

    //         // Fetch courts - not used currently
    //         const courtsResponse = await fetch(`${process.env.REACT_APP_API_URL}/courts/`);
    //         if (!courtsResponse.ok) {
    //             const message = `An error occurred while fetching courts: ${courtsResponse.statusText}`;
    //             window.alert(message);
    //             return;
    //         }
    //         const db_courts = await courtsResponse.json();
    //         setCourts(db_courts);
    //         console.log("DB courtssss:", db_courts);
    //     }
    //     getRecords();
    //     // return;
    // }, []);
    // }, [records.length]);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_84141&layer=geodatastore&bbox-crs=WGS84&bbox=113.8,22.1,114.7,23.0&limit=50&offset=0');
    //             const data = await response.json();

    //             // Extract the coordinates from each record
    //             const coordinates = data.features.map((record) => record.geometry.coordinates);

    //             // Log the coordinates
    //             console.log("coordinates:", coordinates);
    //             console.log("data.features:", data.features)
    //             setTennisCourts(data.features);
    //             const avgLat = tennisCourts.reduce((sum, court) => sum + court.geometry.coordinates[1], 0) / tennisCourts.length;
    //             console.log("courts info:", tennisCourts)
    //             console.log("avgLat:", avgLat)
    //             const avgLng = tennisCourts.reduce((sum, court) => sum + court.geometry.coordinates[0], 0) / tennisCourts.length;
    //             // setCenter(({ lat: avgLat, lng: avgLng }))
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    useEffect(() => {
        const getData = async () => {
            // console.log("new fetch data component test:", FetchData());
            const courts = await FetchData();
            if (debugMode) { console.log("new fetch data:", courts) }
            setTennisCourts(courts.tennisCourts);
            setBballCourts(courts.bballCourts);
            setBasketball_google(courts.basketball_google);
            setFootballCourts(courts.footballCourts)
            setRecords(courts.db_games);
            setCourts(courts.db_courts);
            setCombinedCourts(courts.combinedCourts)
            // console.log("len of combined courts", courts.combinedCourts.length)
        };
        getData();
    }, []);


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_38105&layer=geodatastore&limit=60&offset=0');
    //             const data = await response.json();

    //             // Extract the coordinates from each record
    //             const coordinates = data.features.map((record) => record.geometry.coordinates);

    //             // Log the coordinates
    //             console.log("coordinates:", coordinates);
    //             console.log("data.features:", data.features)
    //             setBballCourts(data.features);
    //             const avgLat = bballCourts.reduce((sum, court) => sum + court.geometry.coordinates[1], 0) / bballCourts.length;
    //             console.log("courts info:", bballCourts)
    //             console.log("avgLat:", avgLat)
    //             const avgLng = bballCourts.reduce((sum, court) => sum + court.geometry.coordinates[0], 0) / bballCourts.length;
    //             // setCenter(({ lat: avgLat, lng: avgLng }))
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    // fetching Google Places API, for 'bball courts in HK'
    // url = f"https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword={encoded_keyword}&location={latitude},{longitude}&radius={radius}&key={API}"
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             console.log("google bball courts api");
    //             const encoded_keyword = "basketball%20court"
    //             // const encoded_keyword = "tennis%20court"
    //             const latitude = '22.28191793495949'
    //             const longitude = '114.17293834153935'
    //             const radius = 50000
    //             const API = 'AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM'
    //             // const response = await fetch(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=${encoded_keyword}&location=${latitude},${longitude}&radius=${radius}&key=${API}`);

    //             // const response = await fetch('https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=coffee%20shops&location=22.28191793495949,114.17293834153935&radius=5000&key=AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM')
    //             const url = `${process.env.REACT_APP_API_URL}/google-places?keyword=${encoded_keyword}&location=${latitude},${longitude}&radius=${radius}&key=${API}`;
    //             // const response = await fetch('/coffee/google-places')
    //             const response = await fetch(url);
    //             if (!response.ok) {
    //                 throw new Error('Request failed');
    //             }
    //             const responseText = await response.text(); // Get the response as text

    //             console.log('Google Basketball API Response:', responseText); // Log the response text

    //             const data = JSON.parse(responseText); // Try parsing the response text as JSON

    //             console.log('Parsed Google Basketball API Response:', data);
    //             setBasketball_google(data.results);
    //         } catch (error) {
    //             console.log('google basketball error:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    // // Soccer pitches
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch('https://api.csdi.gov.hk/apim/dataquery/api/?id=lcsd_rcd_1629267205215_60483&layer=geodatastore&limit=60&offset=0');
    //             const data = await response.json();

    //             // Extract the coordinates from each record
    //             // const coordinates = data.features.map((record) => record.geometry.coordinates);

    //             // Log the coordinates
    //             // console.log("coordinates:", coordinates);
    //             console.log("football data.features:", data.features)
    //             setFootballCourts(data.features);
    //             // const avgLat = tennisCourts.reduce((sum, court) => sum + court.geometry.coordinates[1], 0) / tennisCourts.length;
    //             // console.log("courts info:", tennisCourts)
    //             // console.log("avgLat:", avgLat)
    //             // const avgLng = tennisCourts.reduce((sum, court) => sum + court.geometry.coordinates[0], 0) / tennisCourts.length;
    //             // setCenter(({ lat: avgLat, lng: avgLng }))
    //         } catch (error) {
    //             console.error('Error:', error);
    //         }
    //     };
    //     fetchData();
    // }, []);

    console.log("isAdmin", isAdmin)
    if (!isAuthenticated) {
        return (
            <PasswordPage authenticate={authenticate} />
        )
    }
    if (!token) {
        return (
            <div>
                <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
                    <Navbar2 setToken={setToken} username={username} records={records} debugMode={debugMode} />
                    <SurveyBanner />
                    <Routes>
                        <Route exact path="/login" element={<Login setToken={setToken} debugMode={debugMode} />} />
                        <Route exact path="/register" element={<Register />} debugMode={debugMode} />
                        {/* <Route exact path="/create" element={<Register />} debugMode={debugMode} /> */}
                        <Route exact path="/register_google" element={<RegisterGoogle />} debugMode={debugMode} />
                        {/* <Route key={username} exact path="/table" element={<RecordTable username={username} />} /> */}
                        <Route path="/view/:id" element={<Edit username={username} records={records} tennisCourts={tennisCourts} bballCourts={bballCourts}
                            basketball_google={basketball_google} db_courts={courts} combinedCourts={combinedCourts} />} debugMode={debugMode} />
                        <Route exact path="/landing" element={<Test debugMode={debugMode} />} />
                        <Route key={username} exact path="/sg" element={<RecordTable username={username} region={region} setRegion={setRegion} courts={courts} debugMode={debugMode} />} />
                        <Route exact path="/test2" element={<Test2 username={username} debugMode={debugMode} />} />
                        <Route exact path="/desktop" element={<NewTable username={username} tennisCourts={tennisCourts} bballCourts={bballCourts}
                            basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} combinedCourts={combinedCourts} debugMode={debugMode}
                            records={records} />} />
                        <Route exact path="/newTableMobile_old" element={<NewTableMobile username={username}
                            // tennisCourts={tennisCourts} bballCourts={bballCourts} basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} 
                            combinedCourts={combinedCourts} debugMode={debugMode}
                            records={records} env={env} />} />
                        <Route exact path="/" element={isLoaded ?
                            <NewTableMobile2 username={username}
                                combinedCourts={combinedCourts} debugMode={debugMode}
                                records={records} env={env} />
                            : <div>Loading...</div>
                        } />
                        <Route exact path="/contactus" element={<ContactUs username={username} debugMode={debugMode} />} />
                        {/* <Route exact path="/profile" element={<Profile username={username} debugMode={debugMode} />} /> */}
                        {/* <Route exact path="/passwordpage" element={<PasswordPage authenticate={authenticate} />} /> */}
                        {/* <Route exact path="/messageboard" element={<MessageBoard />} /> */}
                        <Route exact path="/boardgames" element={<BoardGames username={username} tennisCourts={tennisCourts} bballCourts={bballCourts}
                            basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} combinedCourts={combinedCourts} debugMode={debugMode} />} />
                        <Route exact path="/landing2" element={<Landing username={username} debugMode={debugMode} />} />
                        <Route exact path="/map_checkin" element={<MapCheckin username={username} debugMode={debugMode} records={records} combinedCourts={combinedCourts} />} />
                        <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
                    </Routes>
                </AdminContext.Provider>

            </div>
        )
    }

    return (
        <div>
            <AdminContext.Provider value={{ isAdmin, setIsAdmin }}>
                <Navbar2 setToken={setToken} username={username} records={records} debugMode={debugMode} />
                <SurveyBanner debugMode={debugMode} />
                <Routes>
                    <Route exact path="/test2" element={<Test2 username={username} debugMode={debugMode} />} />
                    <Route exact path="/my_games" element={<MyGames username={username} records={records} debugMode={debugMode} />} />

                    <Route key={username} exact path="/sg" element={<RecordTable username={username} region={region} setRegion={setRegion} db_courts={courts} debugMode={debugMode} />} />
                    <Route path="/view/:id" element={<Edit username={username} records={records} tennisCourts={tennisCourts} bballCourts={bballCourts}
                        basketball_google={basketball_google} db_courts={courts} combinedCourts={combinedCourts} debugMode={debugMode} />} />
                    {/* <Route path="/create" element={<Create username={username} records={records} />} /> */}
                    {/* <Route path="/create" element={<NewTable username={username} tennisCourts={tennisCourts} bballCourts={bballCourts} />} /> */}
                    <Route exact path="/desktop" element={<NewTable username={username} tennisCourts={tennisCourts} bballCourts={bballCourts}
                        basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} combinedCourts={combinedCourts} debugMode={debugMode}
                        records={records} />} />
                    <Route exact path="/newTableMobile_old" element={<NewTableMobile username={username}
                        // tennisCourts={tennisCourts} bballCourts={bballCourts} basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} 
                        combinedCourts={combinedCourts} debugMode={debugMode}
                        records={records} env={env} />} />
                    {/* <LoadScript googleMapsApiKey="AIzaSyDOAWLH_bmfUWeVyhmrj92x0lM24UnbmmM"> */}
                    <Route exact path="/" element={isLoaded ?
                        <NewTableMobile2 username={username}
                            combinedCourts={combinedCourts} debugMode={debugMode}
                            records={records} env={env} />
                        : <div>Loading...</div>
                    } />
                    {/* </LoadScript> */}
                    {/* {isLoaded ? <NewTableMobile2 isLoaded={isLoaded} /> : <div>Loading...</div>} */}
                    {/* <Route path="/create_court" element={<CreateCourt username={username} db_courts={courts} debugMode={debugMode} />} /> */}
                    {/* <Route path="/mapsearch" element={<MapWithASearchBox debugMode={debugMode} />} /> */}
                    <Route path="/hk" element={<HomepageHK username={username} region={region} setRegion={setRegion} db_courts={courts} tennisCourts={tennisCourts}
                        bballCourts={bballCourts} debugMode={debugMode} />} />
                    <Route exact path="/contactus" element={<ContactUs username={username} debugMode={debugMode} />} />
                    <Route exact path="/profile" element={<Profile username={username} debugMode={debugMode} />} />
                    {/* <Route exact path="/passwordpage" element={<PasswordPage authenticate={authenticate} />} /> */}
                    <Route exact path="/boardgames" element={<BoardGames username={username} tennisCourts={tennisCourts} bballCourts={bballCourts}
                        basketball_google={basketball_google} db_courts={courts} footballCourts={footballCourts} combinedCourts={combinedCourts} debugMode={debugMode} />} />
                    <Route exact path="/stats" element={<Stats username={username} debugMode={debugMode} combinedCourts={combinedCourts} records={records} />} />
                    <Route exact path="/landing2" element={<Landing username={username} debugMode={debugMode} />} />
                    <Route exact path="/map_checkin" element={<MapCheckin username={username} debugMode={debugMode} records={records} combinedCourts={combinedCourts} />} />
                    <Route exact path="/landing" element={<Test debugMode={debugMode} />} />
                    <Route exact path="/map_search" element={<MapSearch username={username} debugMode={debugMode} records={records} combinedCourts={combinedCourts} />} />
                    <Route exact path="/ui_experiment" element={<ProductFilterDropdowns username={username} debugMode={debugMode} records={records} combinedCourts={combinedCourts} />} />
                    <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
                    {isAdmin && (
                        <>
                            {/* <Route exact path="/map_search" element={<MapSearch username={username} debugMode={debugMode} records={records} combinedCourts={combinedCourts} />} /> */}
                        </>
                    )
                    }
                </Routes>
            </AdminContext.Provider>
            <div style={{ position: 'fixed', bottom: '5px', right: '5px', color: 'grey', fontSize: '10px' }}>{version}</div>
        </div>
    );
};

export default App;